<template>
  <div class="Device" ref="vlbox">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <aside v-show="hide==1">
      <div class="title">
        <p>
          <img src="../assets/imgs/zzjg.png" alt="" />
          组织架构
        </p>
      </div>
      <div class="search">
        <el-input placeholder="组织名称" v-model="form.input2" size="mini">
          <template slot="append">
            <i class="el-icon-search" @click="search"></i>
          </template>
        </el-input>
      </div>

      <div class="company scrollbar_width0" ref="company_main">
        <div  v-show="form.state==1">
              <div v-for="(item, index) in treeList" :key="item.name">
              <el-tooltip
                v-show="item.name.length >= 20"
                class="item"
                effect="light"
                :content="item.name"
                placement="top-start"
              >
                <p
                  class="project_item"
                  @click="projectPChange(item, index)"
                  :class="projectP == index ? 'active' : ''"
                >
                  <i class="el-icon-office-building"></i>
                  <span style="margin-left: 4px">{{ item.name }}</span>
                </p>
              </el-tooltip>
              <p
                class="project_item"
                v-show="item.name.length < 20"
                @click="projectPChange(item, index)"
                :class="projectP == index ? 'active' : ''"
              >
                <i class="el-icon-office-building"></i
                ><span style="margin-left: 4px">{{ item.name }}</span>
              </p>
            </div>
          </div>
        <el-tree
        v-show="form.state==0"
            :data="treeData"
            :props="defaultProps"
            @node-click="handleNodeClick"
          ></el-tree>
      </div>
    </aside>
    <div class="index_right">
        <!-- <img src="../assets/imgs/btn_left.png" alt="" v-if="hide==1" @click="hide=0" class="hide">
        <img src="../assets/imgs/btn_right.png" alt="" v-if="hide==0" @click="hide=1" class="hide"> -->
        <p class="title" style="font-size:14px">
          设备管理
        </p>
        <p class="search">
        <el-input
          type="text"
          size="mini"
          v-model="searchForm.name"
          placeholder=" 请输入设备号/设备名称"
        ></el-input>
        <button class="btn1" @click="currentPage2=1;getData()" v-if="permissoin.includes('query')">
          <i class="el-icon-search"></i>
          查询
        </button>
         <button class="btn2" @click="modal=1" v-if="permissoin.includes('add')">
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border>
            <el-table-column type="index" label="排序" width="80">
            </el-table-column>
            <el-table-column prop="name" label="设备名称" >
            </el-table-column>
            <el-table-column prop="sn" label="视频编号">
            </el-table-column>
            <el-table-column prop="orgName" label="所属组织" width="180">
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <!-- {{ scope.row.online==1?'在线':'离线' }} -->
                <el-button type="success" plain v-if="scope.row.online==1" size="mini">在线</el-button>
                <el-button type="danger" plain v-else-if="scope.row.online==0" size="mini">离线</el-button>
                <el-button type="warning" plain v-else size="mini">异常</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit" 
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-delete" 
                    v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-umbrella" 
                    v-if="permissoin.includes('hidden')&&scope.row.hidden==1"
                      :command="{ num: 4, data: scope.row }"
                      >隐藏</el-dropdown-item>
                      <el-dropdown-item
                    icon="el-icon-video-camera" 
                    v-if="permissoin.includes('hidden')&&scope.row.hidden==0"
                      :command="{ num: 5, data: scope.row }"
                      >显示</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          prev-text="上一页"
          next-text="下一页"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    </div>
    <div class="modal" v-show="modal==1">
        <div class="inner">
          <p class="title">
          <span>新增设备</span>
          <span @click="modal = 0;netArrUpdate=[{id:0}]" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" label-position="left">
              <!-- <el-form-item label="项目名称:" prop="name">
                <el-input v-model="ruleForm.name" size='mini'></el-input>
              </el-form-item> -->
              <el-form-item label="所属机构:" prop="orgId">
                <el-cascader
                :collapse-tags="true"
                ref="cas"
                size="mini"
                v-model="ruleForm.orgId"
                :props="props"
                :options="treedata"
                filterable
              >
              </el-cascader>
              </el-form-item>
              </el-form>
              <div class="text_item">
                <span>*</span>设备信息填写  <span @click="addFormUpdateItem">
                  <i class="el-icon-plus"></i>
                  增加设备</span>
              </div>
               <div class="net" v-for="(item,index) in netArrUpdate" :key="item.key">
                  <p>
                    <span class="spaa"><span class="star">* </span>设备编号</span>
                    <el-input v-model="item.sn" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>设备名称</span>
                    <el-input v-model="item.name" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>通 道 号</span>
                    <el-input v-model="item.aisleNo" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>监控点号</span>
                    <el-input v-model="item.monitorNo" size="mini"></el-input><el-button type="primary" size="mini" @click="videoShow(item.monitorNo)">预览</el-button>
                  </p>
                                    <p>
                    <span class="spaa"><span class="star">* </span>是否隐藏</span>
            <el-switch
              v-model="item.hidden"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="隐藏"
              :active-value="0"
              :inactive-value="1"
              inactive-text="显示"
            >
            </el-switch>
                  </p>
                  <div style="display:flex"><p class="line"></p>
                  <span style="cursor: pointer;position: relative;top: -6px;" @click="deletenvritem(index)" v-show="index>0"><i class="el-icon-close">删除</i></span>
                  </div>
            </div>
        </div>
        <div class="bottom">
            <button class="btn_red" @click="onsubmit">保存</button>
            <button class="btn_gray" @click="modal = 0;netArrUpdate=[{id:0}]">取消</button>
        </div>
          </div>
    </div>
    <div class="modal" v-show="modal==2">
        <div class="inner">
          <p class="title">
          <span>编辑设备</span>
          <span @click="modal = 0;ruleForm={}" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0" style='margin-top:20px'>
            <!-- <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" label-position="left">
              <el-form-item label="所属机构:" prop="orgId">
                <el-cascader
                :collapse-tags="true"
                ref="cas"
                size="mini"
                v-model="ruleForm.orgId"
                :props="props"
                :options="treedata"
                filterable
              >
              </el-cascader>
              </el-form-item>
              </el-form> -->
              <!-- <div class="text_item">
                <span>*</span>设备信息修改
              </div> -->
              <div class="net">
                  <p>
                    <span class="spaa"><span class="star">* </span>设备编号</span>
                    <el-input v-model="ruleForm.sn" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>设备名称</span>
                    <el-input v-model="ruleForm.name" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>通 道 号</span>
                    <el-input v-model="ruleForm.aisleNo" size="mini"></el-input>
                  </p>
                  <p>
                    <span class="spaa"><span class="star">* </span>监控点号</span>
                    <el-input v-model="ruleForm.monitorNo" size="mini"></el-input>
                    <el-button type="primary" size="mini" v-show="ruleForm.hidden==1" @click="videoShow(ruleForm.monitorNo)">预览</el-button>
                  </p>

                  </div>

        </div>
        <div class="bottom">
            <button class="btn_red" @click="onsubmit1">保存</button>
            <button class="btn_gray" @click="modal = 0;ruleForm={}">取消</button>
        </div>
          </div>
    </div>
    <div class="modal1" v-show="popup == 1">
      <div class="popup" ref="vlmodal">
        <div class="title">
          <p class="blue">
            <i class="el-icon-info"></i>您可以点击
            <span class="weight">鼠标右键</span>
            开启云台控制，对视频画面进行缩小、放大、旋转操作。
          </p>
          <span @click="close">
            <i class="el-icon-close"></i>
          </span>
        </div>
        <div class="hk">
          <div id="playWnd" class="playWnd"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WebControl } from "../../public/static/web-control.esm.min";
import {
  getAll,deviceAdd,getDeviceList,deviceDelete,updateDevice,hiddenVideo
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: {state:0},
      info: 1,
      currentPage2:1,
      treeData:[],
      hide:1,
      modal:0,
      treeList:[],
      total:0,
      searchForm:{},
      permissoin:[],
      projectP:-1,
      dialogVisible:false,
      netArrUpdate:[{id:0}],
      ruleForm:{
        pwd:123456
      },
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
       //当前播放视屏监控点号
      currentCIColde: [],
      cameraindexcode: "",
      //当前播放视屏序列
      currentSequence: 0,
      popup: 0,
      //插件对象
      oWebControl: null,
      timeOut:0,
      treedata:[],
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableData: [],
        rules: {
          orgId: [
            { required: true, message: '选择机构', trigger: 'blur' },
          ],
          }
    };
  },
  methods: {
    getData(){
      let data={
          orgId: this.currentId,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: this.pageSize,
      }
      getDeviceList(data).then(res=>{
        if(res.code==200){
          this.tableData=res.data.list
          this.total=res.data.total-0
        }else{
          this.tableData=[]
          this.total=0
        }
      })
    },
    deletePro() {
      let data = { id: this.ruleForm.id };
      deviceDelete(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    projectPChange(v,i){
      this.projectP=i
        this.currentId = v.id;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    search(){
      if(this.form.input2!=undefined||this.form.input2!=''){
        this.form.state=1
        let data={name:this.form.input2}
      getAll(data).then(res=>{
        if(res.code==200){
          this.treeList=res.data
        }
      })
      }else{
        this.form.state=0
        this.getdata()
      }
      
    },
    handleNodeClick(data) {
      this.companyName = data.name;
      this.currentId = data.id;
      this.currentPage2=1
      this.getData()
    },
    onsubmit(){
      let data={
        orgId:this.ruleForm.orgId?this.ruleForm.orgId[this.ruleForm.orgId.length-1]:'',
        json:JSON.stringify(this.netArrUpdate),
      }
      deviceAdd(data).then(res=>{
        if(res.code==200){
          this.getData()
          this.$message({
            type:'success',
            message:res.msg
          })
          this.modal=0
          this.netArrUpdate=[{id:0}]
        }else{
          this.$message.error(res.data.msg)
        }
      })     
    },
    onsubmit1(){
      let data={
        sn:this.ruleForm.sn,
        name:this.ruleForm.name,
        aisleNo:this.ruleForm.aisleNo,
        monitorNo:this.ruleForm.monitorNo,
        id:this.ruleForm.id,
      }
      updateDevice(data).then(res=>{
        if(res.code==200){
          this.getData()
          this.$message({
            type:'success',
            message:res.msg
          })
          this.modal=0
          this.ruleForm={}
        }else{
          this.$message.error(res.data.msg)
        }
      })     
    },
    deletenvritem(index){
        this.netArrUpdate.splice(index,1);
    },
    handleCommand(d){
      this.ruleForm=JSON.parse(JSON.stringify(d.data)) 
      if(d.num==2){
        this.modal=2
        
      }else if(d.num==3){
          this.dialogVisible=true
      }else if(d.num==4){
        hiddenVideo({id:d.data.id,hidden:0}).then(res=>{
             if(res.code==200){
          this.getData()
          this.$message({
            type:'success',
            message:res.msg
          })
        }else{
          this.$message.error(res.data.msg)
        }
        })
      }else if(d.num==5){
        hiddenVideo({id:d.data.id,hidden:1}).then(res=>{
             if(res.code==200){
          this.getData()
          this.$message({
            type:'success',
            message:res.msg
          })
        }else{
          this.$message.error(res.data.msg)
        }
        })
      }
    },
    addFormUpdateItem(){
      this.$set(this.netArrUpdate,this.netArrUpdate.length,{key:this.netArrUpdate.length,id:''})
    },
      //播放视频
    videoShow(cameraindexcode) {
      this.currentCIColde = cameraindexcode.split(",");
      this.currentSequence = 0;
      this.popup = 1;
      this.$nextTick(() => {
        this.$refs.vlmodal.style.height = this.$refs.vlbox.offsetHeight + "px";
        this.$refs.vlmodal.style.width =
          (this.$refs.vlbox.offsetHeight - 50) * 1.78 + "px";
      });
      this.initPlugin();
    },
     close() {
      this.popup = 0;
      // 标签关闭
      if (this.oWebControl != null) {
        this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        this.oWebControl.JS_Disconnect().then(
          function () {},
          function () {}
        );
      }
    },
     //初始化
    init() {
      var _this = this;
      _this.getPubKey(function () {
        var appkey = "26177308";
        var secret = "3u7SqzJ73wmfHnMdRKnW";
        var ip = "118.123.244.181";
        var szPort = "443";
        var snapDir = "D:SnapDir";
        var videoDir = "D:VideoDir";
        var layout = "1x1";
        // var encryptedFields = ["secret"];
        var szShowToolbar = "1";
        var szShowSmart = "1";
        var btIds = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769";

        appkey = appkey.replace(/(^\s*)/g, "");
        appkey = appkey.replace(/(\s*$)/g, "");

        secret = secret.replace(/(^\s*)/g, "");
        secret = secret.replace(/(\s*$)/g, "");

        ip = ip.replace(/(^\s*)/g, "");
        ip = ip.replace(/(\s*$)/g, "");

        szPort = szPort.replace(/(^\s*)/g, "");
        szPort = szPort.replace(/(\s*$)/g, "");

        snapDir = snapDir.replace(/(^\s*)/g, "");
        snapDir = snapDir.replace(/(\s*$)/g, "");

        videoDir = videoDir.replace(/(^\s*)/g, "");
        videoDir = videoDir.replace(/(\s*$)/g, "");

        var port = parseInt(szPort);
        var showSmart = parseInt(szShowSmart);
        var showToolbar = parseInt(szShowToolbar);
        var enableHttps = 1;

        // encryptedFields = "secret";
        console.log({
          appkey: appkey,
          secret: secret,
          ip: ip,
          playMode: 0, // 预览
          port: port,
          snapDir: snapDir,
          videoDir: videoDir,
          layout: layout,
          enableHTTPS: enableHttps,
          showToolbar: showToolbar,
          showSmart: showSmart,
          buttonIDs: btIds,
          // encryptedFields: encryptedFields,
        });
        _this.oWebControl
          .JS_RequestInterface({
            funcName: "init",
            argument: JSON.stringify({
              appkey: appkey,
              secret: secret,
              ip: ip,
              playMode: 0, // 预览
              port: port,
              snapDir: snapDir,
              videoDir: videoDir,
              layout: layout,
              enableHTTPS: enableHttps,
              showToolbar: showToolbar,
              showSmart: showSmart,
              buttonIDs: btIds,
              // encryptedFields: encryptedFields,
            }),
          })
          .then(function (oData) {
            _this.oWebControl.JS_Resize(
              (_this.$refs.vlbox.offsetHeight - 50) * 1.78,
              _this.$refs.vlbox.offsetHeight - 50
            ); // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            _this.startPreview();
            console.log("初始化", oData);
          });
      });
    },
    // 获取公钥
    getPubKey(callback) {
      var _this = this;
      _this.oWebControl
        .JS_RequestInterface({
          funcName: "getRSAPubKey",
          argument: JSON.stringify({
            keyLength: 1024,
          }),
        })
        .then(function (oData) {
          if (oData.responseMsg.data) {
            _this.pubKey = oData.responseMsg.data;
            callback();
          }
        });
    },
    //视屏预览
    startPreview() {
      var _this = this;
      //监控点编号
      // var cameraIndexCode = "185dad0787e94fefad7dc8df57da33ba";
      var cameraIndexCode = _this.currentCIColde[this.currentSequence];
      var streamMode = 0;
      var transMode = 1;
      var gpuMode = 0;
      var wndId = -1; //默认为空闲窗口回放
      cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, "");
      cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, "");
      this.oWebControl
        .JS_RequestInterface({
          funcName: "startPreview",
          argument: JSON.stringify({
            cameraIndexCode: cameraIndexCode,
            streamMode: streamMode,
            transMode: transMode,
            gpuMode: gpuMode,
            wndId: wndId,
          }),
        })
        .then(function (oData) {
          _this.currentSequence++;
          console.log(oData);
          if (_this.currentSequence < _this.currentCIColde.length) {
            _this.timeOut = setTimeout(function () {
              _this.startPreview();
            }, 1000);
          } else if (_this.currentSequence == 25) {
            _this.$message("视屏最多支持播放24条");
            clearTimeout(_this.timeOut);
          } else {
            clearTimeout(_this.timeOut);
          }
        });
    },
    //回调函数
    cbIntegrationCallBack(oData) {
      var msg = JSON.stringify(oData.responseMsg.msg);
      msg = msg.substring(1, msg.length - 1);
      var msgArray = msg.split(",");
      var cameraIndex = msgArray[0];
      if (msgArray[0].length > 25) {
        var array = cameraIndex.split(":");
        this.currentCIColdeCJ = array[1].substring(2, array[1].length - 2);
      }
      // showCBInfo(JSON.stringify(oData.responseMsg));
    },
    // 初始化插件
    initPlugin() {
      var _this = this;
      var oWebControl = new WebControl({
        szPluginContainer: "playWnd",
        iServicePortStart: 15900,
        iServicePortEnd: 15909,
        szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11", // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function () {
          _this.oWebControl.JS_SetWindowControlCallback({
            cbIntegrationCallBack: _this.cbIntegrationCallBack,
          });
          oWebControl
            .JS_StartService("window", {
              dllPath: "./VideoPluginConnect.dll",
            })
            .then(function () {
              oWebControl.JS_CreateWnd("playWnd", 1184, 665).then(function () {
                _this.init();
              });
            });
        },
        cbConnectError: function () {
          console.log("cbConnectError");
          _this.oWebControl = null;
          _this.$message("插件未启动，正在尝试启动，请稍候...");
          WebControl.JS_WakeUp("VideoWebPlugin://");
          let initCount = 0;
          initCount++;
          if (initCount < 3) {
            setTimeout(function () {
              _this.initPlugin();
            }, 3000);
          } else {
            _this.$message("插件启动失败，请检查插件是否安装！");
          }
        },
        cbConnectClose: function (bNormalClose) {
          // 异常断开：bNormalClose = false
          // JS_Disconnect正常断开：bNormalClose = true
          console.log(bNormalClose);
          _this.oWebControl = null;
        },
      });
      this.oWebControl = oWebControl;
    },
  },
  mounted() {
    
    //获取权限
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){
        this.permissoin.push(item.permission)    
      }
    })
    this.getData()
  getAll().then((res) => {
      if (res.code == 200) {
        this.treeData = res.data;
        this.treedata = res.data;
        this.currentId = "";
      } else {
        this.treeData = [];
      }
    });
    this.$refs.company_main.style.height =
      this.$refs.index_main.offsetHeight*0.8795 + "px";
  },
   destroyed() {
    // 标签关闭
    if (this.oWebControl != null) {
      this.oWebControl.JS_HideWnd(); // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      this.oWebControl.JS_Disconnect().then();
    }
    clearTimeout(this.timeOut);
  },
  
};
</script>

<style lang="less" >
.Device{
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      
      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 780px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
          border-bottom: 1px dashed #ebf0f5;
          font-size: 12px;
          padding-left: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }
        .active {
          color: #466cd4;
        }
    }

  }
  .index_right{
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 28px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 268px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px  #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center{
        flex: 1;
        padding-left: 34px;
        box-sizing: border-box;
        .el-button--mini, .el-button--mini.is-round{
          padding:  6px 12px;
          margin-left: 4px;
        }
          .text_item{
            margin-bottom: 12px;
            &>span:nth-child(1){
              color: #F56C6C;
              margin-right: 2px;
            }
            &>span:nth-child(2){
              display: inline-block;
              margin-left: 198px;
              cursor: pointer;
              color: #d62829;
              i{
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
          .net{
            .line{
              border-top: 1px dashed rgb(221, 214, 214);
              width: 348px;
            }
            p{
              margin-bottom: 20px;
            }
            .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input{
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
          }
          
          .el-form{
            .el-form-item__label{
              font-size: 12px;
              color: #222328;
            }
            .el-form-item{
              margin-bottom: 10px;
            }
            .el-input{
              width: 270px;
            }
            .el-form-item__error{
              padding-top: 0px;
            }
          }
        
      }
      .bottom{
        height: 60px;
        display: flex;
        justify-content: center;
        &>button:nth-child(1){
          margin-right: 20px;
        }
      }
    }
   }
   .modal1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    z-index: 1000;
    box-sizing: border-box;
    .popup {
      background-color: #fff;
      width: 1184px;
      height: 720px;
      box-sizing: border-box;
      margin: auto;
      .playWnd {
        width: 1184px;
        height: 665px;
        // border: 1px solid red;
      }
      .hk {
        width: 1184px;
        height: 665px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        background-color: #fff;
        border-bottom: 1px solid #1e3142;
        .blue {
          line-height: 50px;
          margin-left: 16px;
          font-size: 14px;
          .weight {
            font-weight: 600;
          }
          i {
            font-size: 22px;
            color: #ff6400;
            margin-right: 10px;
            position: relative;
            top: 3px;
          }
        }
        .btns {
          margin-right: 20px;
          .btn_r {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #fe0000;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
          .btn_g {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #16bb9b;
            text-align: center;
            margin-right: 22px;
            cursor: pointer;
          }
          .btn_gray {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #3d3d3d;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
          .btn_b {
            width: 100px;
            line-height: 30px;
            border-radius: 8px;
            text-align: center;
            color: #fff;
            background-color: #0090ff;
            text-align: center;
            margin-right: 18px;
            cursor: pointer;
          }
        }
        .el-icon-close {
          cursor: pointer;
          font-size: 18px;
          margin-right: 22px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>